<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="create"
          >新建</el-button
        >
      </div>
      <el-table :data="list" style="width: 100%" stripe>
        <el-table-column
          prop="image"
          label="图片"
          align="center"
        >
          <template slot-scope="scope">
            <img v-lazy="scope.row.image" class="sg-img-sm" />
          </template>
        </el-table-column>
        <el-table-column
          prop="url"
          label="链接"
          align="center"
        ></el-table-column>
        <el-table-column prop="sort" label="排序" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" size="mini">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteRow(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="sg-pagination">
        <el-pagination
          @current-change="pageChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :title="action == 'create' ? '新建' : '编辑'"
      :visible.sync="showDialog"
      width="60%"
      top="5vh"
    >
      <div class="sg-dialog-body">
        <el-row>
          <el-col :sm="{ span: 18, offset: 3 }">
            <el-form
              ref="createEditForm"
              :model="item"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item prop="image" label="图片">
                <!-- <UploadCard v-model="item.image"></UploadCard> -->
                <sg-upload v-model="item.image"></sg-upload>
              </el-form-item>
              <el-form-item prop="url" label="链接">
                <el-input
                  v-model="item.url"
                  placeholder="请输入链接"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="sort" label="排序">
                <el-input
                  v-model.number="item.sort"
                  placeholder="请输入排序"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapState } from "vuex"

export default {
  name: "Carousel",
  data() {
    return {
      title: "首页轮播",
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      showDialog: false,
      pre_uri: "carousels/",
      // create/update
      action: "create",
      emptyItem: {
        image: "",
        url: "",
        sort: "",
      },
      item: {},
      searchItem: {
        name: "",
      },
      rules: {}
    };
  },
  computed: {
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      this.current = 1
      this.getList()
    },
    getList() {
      var that = this;
      this.$store.commit("updateLoading", true);
      this.$http
        .post(
          this.pre_uri + "get",
          Object.assign(
            {
              page: this.current,
            },
            this.searchItem
          )
        )
        .then((res) => {
          this.$store.commit("updateLoading", false);
          if (res.code == 0) {
            that.$util.parsePagiList(that, res.data);
          }
        });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    create() {
      this.item = Object.assign({}, this.emptyItem);
      this.action = "create";
      this.switchShowDialog(true);
    },
    edit(item) {
      this.item = Object.assign({}, item, {
        password: "",
        password_confirm: "",
      });
      this.action = "edit";
      this.switchShowDialog(true);
    },
    deleteRow(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(this.pre_uri + "delete", {
              id: row.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList()
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    switchShowDialog(show) {
      this.showDialog = show;
    },
    submit() {
      var url = this.pre_uri + (this.action == "create" ? "store" : "update");
      this.$http.post(url, this.item).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.switchShowDialog(false);
          this.getList();
        }
      });
    },
  },
};
</script>
